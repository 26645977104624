/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:ae13d595-591b-4ea3-a94b-068573580aca",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_uYv01ZfZN",
    "aws_user_pools_web_client_id": "6molm34no1ko8oiscdmbid9d9p",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6ki6qs5y2fabna77ugnd67trrq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-27wnbkiybvdhhcklne4uimchue",
    "aws_user_files_s3_bucket": "webshopimagesstorage144027-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
